
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Matin";
  src: url("./../fonts/matin/Matin.ttf");
}

@layer utilities {
    .footer-bg-light {
        background: rgb(236,227,214);
        background: linear-gradient(180deg, rgba(236,227,214,1) 0%, rgba(241,238,233,1) 325%);
    }

    .footer-bg-dark {
        background: rgb(20,33,64);
        background: linear-gradient(180deg, rgba(20,33,64,1) 0%, rgba(42,64,116,1) 325%);
    }
}

@import './HeroStyle.css';
@import './AboutStyle.css';
@import './ProjectStyle.css';
@import './FooterStyle.css';


body {
  @apply bg-bgLight dark:bg-bgDark font-mohave cursor-default select-none overflow-x-hidden transition-colors-1
}

.max-width-01 {
  @apply max-w-7xl m-auto
}

.transition-colors-1 {
  @apply transition-colors duration-500
}