
.bg-gradient {
    @apply bg-[url('./../../assets/images/bg-gradient/gradient-light-bg.svg')] dark:bg-[url('./../../assets/images/bg-gradient/gradient-dark-bg.svg')]
}

.hero-section-bg {
    @apply bg-gradient bg-no-repeat bg-cover bg-[left_center] w-screen h-screen relative
}

.bg-gradient-front {
    @apply bg-gradient bg-no-repeat bg-cover bg-[left_center] w-screen h-screen opacity-90 absolute z-30
}

.city {
    @apply bg-[url('./../../assets/images/city.webp')] bg-repeat-x bg-cover w-screen h-screen absolute z-20
}

.cloud {
    @apply w-screen h-screen -translate-y-28 absolute
}

.box-circle {
    @apply w-full h-full flex items-center justify-center pb-[10%]
}

.circle {
    @apply w-[12.5rem] h-[12.5rem] bg-[#FFF] rounded-full z-40
}

.circle-light {
    @apply circle;
    box-shadow: 0 0 0 15px #81C6E8, 0 0 0 30px #5DA7DB;
}

.circle-dark {
    @apply circle;
    box-shadow: 0 0 0 15px #007ad6, 0 0 0 30px #035fa3;
}

.three-3d {
    @apply w-[17rem] h-[17rem] absolute -translate-y-10 z-40
}

.hero-neon-title-box {
    @apply absolute mt-80 z-40
}

.hero-neon-title{
    @apply text-5xl md:text-6xl text-[#81C6E8] tracking-widest text-center flex flex-col flicker-slow-2;
    font-family: 'holtwood', cursive;
    text-shadow: 1px 0px 4px #7DE5ED, 2px 0px 4px #7DE5ED, 3px 0px 4px #7DE5ED, 2px 0px 3px #81C6E8, 2px 3px 15px #D42CCA, 2px 0px 15px, 5px 0px 125px, 20px 0vw 200px #81C6E8,40px 0vw 200px #D42CCA;
}

.gear {
    @apply bg-[url('./../../assets/images/gear.svg')] bg-no-repeat bg-cover absolute animate-[spin_5.5s_linear_infinite]
}

.gear-box-lg {
    @apply absolute mt-64 ml-32 gear w-48 h-48 opacity-40 z-30
}

.gear-box-sm {
    @apply absolute gear mt-56 ml-24 w-10 h-10 opacity-90 z-50
}

.hero-btn-container {
    @apply absolute mt-[32rem] z-40;
}

.hero-btn-wrap {
    /* @apply mt-[32.5rem] */
}

.hero-btn {
    @apply w-56 h-12 rounded-full transition-all duration-500 ease-in-out;
    left: calc(50% - 125px);
    bottom: calc(100vh - 70px);
}

.hero-btn-gradient-light {
    background: linear-gradient(-45deg, #5DA7DB, #81C6E8, #23a6d5, #7DE5ED);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}
    
.hero-btn-gradient-dark {
    background: linear-gradient(-45deg, #035fa3, #007ad6, #0c769c, #124b75);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

.hero-btn-envelope {
    @apply invisible w-0 h-0 transition-all duration-500 ease-in-out
}
    
.hero-btn span {
    @apply block transition-all duration-500 ease-in-out visible text-lg py-2 px-10 text-center font-holtwood;
}

.hero-btn-span-gradient-light {
    background: linear-gradient(-45deg, #000, #23a6d5, #81C6E8, #000);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    color: transparent;
    background-clip: text;
}
    
.hero-btn-span-gradient-dark {
    background: linear-gradient(-45deg, #000, #0c769c, #007ad6, #000);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    color: transparent;
    background-clip: text;
}

.hero-btn-floating {
    @apply rounded-full fixed w-11 h-11 md:w-12 md:h-12 bottom-6 bg-blue-900;
    left: calc(100% - 70px);
}

.hero-btn-hidden {
    @apply -bottom-96
}

.hero-btn-floating .hero-btn-envelope {
    @apply visible w-7 h-7 md:w-8 md:h-8 translate-y-[0.2rem] m-auto
}

.hero-btn-floating span {
    @apply invisible;
    font-size: 0;
}







.flicker-slow{
    animation: flicker 3s linear infinite;
}
    
.flicker-slow-2{
    animation: flicker 6s linear infinite;
}

.flicker-fast{
    animation: flicker 1s linear infinite;
}

@keyframes flicker {
    0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
        //text-shadow: none;
    opacity: .99;
        
    }
    20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
        opacity: 0.4;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
  }
