
.contact-card {
    @apply w-full lg:w-[45%] bg-white dark:bg-[#2E3949]  px-[.7rem] pt-[.7rem] mt-11 rounded-2xl flex flex-col gap-5 font-fredoka-one
}

.contact-field {
    @apply bg-[#F5F5F5] dark:bg-slate-800 rounded-2xl
}

.contact-span {
    @apply block pt-3 md:pt-1 lg:pt-3 px-5 text-sm text-[#ACACAC] dark:text-[#ededed]
}

.contact-span-error {
    @apply block pt-3 md:pt-1 lg:pt-3 px-5 text-sm text-red-600 dark:text-red-500
}

.contact-input {
    @apply w-full bg-transparent pb-3 md:pb-2 px-5 text-xl tracking-wide rounded-2xl focus:outline-none text-textPrimaryDark dark:text-textPrimaryLight
}

.contact-send-btn-wrap {
    @apply bg-[#2E3949] dark:bg-white absolute top-0 left-0 right-0 bottom-0 h-[32px] w-[32px] lg:h-[35px] lg:w-[35px] rounded-full z-0 transition-all duration-500 ease-in-out origin-center overflow-hidden translate-x-7;
  
    transform: scale3d(0, 0, 1) translateX(-50%);
    transform-style: preserve-3d;
}

.contact-send-btn:hover .contact-send-btn-wrap {
    @apply dark:bg-opacity-90;
    transform: scale3d(10, 10, 1);
}