
.text-about-title {
    @apply text-5xl font-righteous tracking-widest text-textPrimaryDark dark:text-textPrimaryLight
}

.text-about-sub-title {
    @apply text-lg font-medium tracking-wider text-textQuaternaryLight dark:text-textQuaternaryDark
}

.text-about-content {
    @apply font-poppins flex flex-col text-textPrimaryDark dark:text-textPrimaryLight
}

.skill-card {
    @apply bg-bgLight2 dark:bg-bgDark2 w-full rounded-lg pb-12 pl-5 pt-5
}
