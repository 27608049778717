
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";


.project-card {
    @apply h-full bg-white dark:bg-[#2E3949] relative pointer-events-none;

    transform: scale(0.8);
    transition: 700ms;
    opacity: 0.3;
    
}

.project-card-active {
    @apply cursor-default pointer-events-auto; 
    
    opacity: 1;
    transform: scale(1);
}

.project-card, .project-card-img {
    @apply rounded-[0.90rem]
}

.project-lang-framework {
    @apply bg-[#F7D69B] border-[#F7D69B] dark:bg-[#5B8DF0] dark:border-[#5B8DF0] dark:text-white
}

.project-lang-package {
    @apply bg-[#F78B8C] border-[#F78B8C] dark:bg-[#DE9F2F] dark:border-[#DE9F2F] dark:text-white
}

.project-lang-base {
    @apply bg-white border-slate-500 text-slate-500 dark:bg-[#2E3949] dark:text-slate-200 border dark:border-slate-200
}